import { Controller } from '@hotwired/stimulus';
import PhotoSwipe from 'photoswipe';
import PhotoSwipeLightbox from 'photoswipe/lightbox';

export default class extends Controller {
  static targets = ['item'];

  connect() {
    this.lightbox = new PhotoSwipeLightbox({
      gallery: this.element,
      children: this.itemTargets,
      pswpModule: PhotoSwipe,
    });

    this.lightbox.init();
  }

  disconnect() {
    this.lightbox.destroy();
  }
}
