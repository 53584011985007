import { onConnect } from '@sonicgarden/onconnect';

const FIT_CONTENT_MAX_HEIGHT = 450;

// Facebookみたいに改行に合わせて伸びるフォーム
// http://qiita.com/mizchi/items/c39fd4b532f702c22d18
const fitToContent = function fitToContent(el, maxHeight) {
  let adjustedHeight = el.clientHeight;
  if (!maxHeight || maxHeight > adjustedHeight) {
    adjustedHeight = Math.max(el.scrollHeight, adjustedHeight);

    if (maxHeight) {
      adjustedHeight = Math.min(maxHeight, adjustedHeight);
    }

    if (adjustedHeight > el.clientHeight) {
      el.style.height = `${adjustedHeight}px`;
    }
  }
};

$(document).on('input', 'textarea', (event) => {
  const target = event.currentTarget;
  const maxHeight = $(target).data('maxHeight') || FIT_CONTENT_MAX_HEIGHT;
  fitToContent(target, maxHeight);
});

onConnect('textarea', (target) => {
  $(target).trigger('input');
});
