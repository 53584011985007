import { Controller } from '@hotwired/stimulus';
import copy from 'clipboard-copy';

export default class extends Controller {
  copy(event) {
    event.preventDefault();
    const target = event.currentTarget;
    copy(target.dataset.clipboardText);
    App.notifySuccess('クリップボードにコピーしました');
  }
}
