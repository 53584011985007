import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['modal'];

  connect() {
    this.myModal = $(this.modalTarget);
  }

  show() {
    this.myModal.modal('show');
  }

  submitEnd(e) {
    if (e.detail.success && e.detail.formSubmission.submitter) {
      this.myModal.modal('hide');
    }
  }
}
