import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['checkbox', 'footer', 'footerAll', 'selectAllCheckbox'];

  connect() {
    this.footerTarget.classList.add('d-none');
    this.footerAllTarget.classList.add('d-none');
  }

  change() {
    this.footerTarget.classList.add('d-none');
    this.footerAllTarget.classList.add('d-none');

    if (this.selectAllCheckboxTarget.checked) {
      this.footerAllTarget.classList.remove('d-none');
      return
    }

    const checked = this.checkboxTargets.some((checkbox) => checkbox.checked);
    if (checked) {
      this.footerTarget.classList.remove('d-none');
    }
  }

  click(e) {
    const checkedTravelHelperIds = this.checkboxTargets.map((checkbox) => {
      if (!checkbox.checked) return null;
      const { travelHelperId } = checkbox.dataset;
      return travelHelperId;
    }).filter((v) => v);

    if (checkedTravelHelperIds.length === 0) {
      e.preventDefault();
      alert('トラベルヘルパーを選択してください');
    } else {
      const url = new URL(e.target.href);
      url.searchParams.delete('travel_helper_ids[]');

      checkedTravelHelperIds.forEach((id) => {
        url.searchParams.append('travel_helper_ids[]', id);
      });
      e.target.href = url;
    }
  }

  selectAllChange(e) {
    this.change(e)
  }

  clickSelectAll(e) {
    if(!this.selectAllCheckboxTarget.checked) {
      e.preventDefault();
      alert('全てのトラベルヘルパーに依頼する場合はチェックボックスを選択してください。');
    }
  }
}
