import { Turbo } from '@hotwired/turbo-rails';
import jQuery from 'jquery';
import _ from 'lodash';
import moment from 'moment';
import Rails from 'rails-ujs';

window.jQuery = jQuery;
window.$ = jQuery;
window._ = _;
window.moment = moment;
window.Rails = Rails;

Rails.start();
Turbo.session.drive = false;
window.App = window.App || {};
