import jQuery from 'jquery';
import _ from 'lodash';

document.addEventListener('DOMContentLoaded', () => {
  // SP Menu
  $('.js-aelz-nav-toggle').on('click', () => {
    $('.js-aelz-nav-toggle, .nav').toggleClass('show');
  });
  $('#menu a[href]').on('click', () => {
    $('.js-aelz-nav-toggle').trigger('click');
  });

  // NOTE: 高畑さんのコードをそのまま使用
  if (document.getElementById('map-home') || document.getElementById('aelz-home')) {
    const $window = $(window);
    const header = $('.site-header');
    const heroBottom = $('.hero-block').height();

    const throttledScroll = _.throttle(() => {
      if ($window.scrollTop() > heroBottom) {
        header.addClass('fixed');
      } else {
        header.removeClass('fixed');
      }
    }, 100);

    $window.on('scroll', throttledScroll);
    $window.trigger('scroll');
  }
});
