import Swiper, { Navigation, Pagination, Thumbs } from 'swiper';

document.addEventListener('DOMContentLoaded', () => {
  const body = $('body');
  const smoothScrollTrigger = document.querySelectorAll('a[href^="#map-"]');
  for (let i = 0; i < smoothScrollTrigger.length; i++){
    smoothScrollTrigger[i].addEventListener('click', (e) => {
      e.preventDefault();
      let href = smoothScrollTrigger[i].getAttribute('href');
      let targetElement = document.getElementById(href.replace('#', ''));
      const top = targetElement.offsetTop;
      body.scrollTop(top);
    });
  }

  const swiperThumbs = new Swiper('.js-swiper-thumbs', {
    spaceBetween: 10,
    slidesPerView: 3,
    freeMode: true,
    watchSlidesProgress: true,
    direction: 'horizontal',
    breakpoints: {
      992: {
        direction: 'vertical',
      },
    },
  });

  new Swiper('.js-swiper-main', {
    modules: [Navigation, Pagination, Thumbs],
    spaceBetween: 10,
    thumbs: {
      swiper: swiperThumbs,
    },
  });

  new Swiper('.js-accommodation-review-items', {
    modules: [Navigation, Pagination],
    spaceBetween: 60,
    loop: true,
    pagination: {
      el: '.swiper-pagination'
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  });
});
