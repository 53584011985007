import 'core-js/stable';
import 'regenerator-runtime/runtime';
import './lib/initialize';

import 'jquery-ui/ui/effect.js';
import 'bootstrap-sass/assets/javascripts/bootstrap.js';
import 'bootstrap-notify/bootstrap-notify.min.js';
import 'select2/dist/js/select2.full.min.js';
import 'jquery-match-height/dist/jquery.matchHeight-min.js';
import 'flatpickr';
import '@sonicgarden/auto-submit-form-element';
import '@sonicgarden/leave-check-form-element';

import './lib/stimulus.js';
import './lib/sortable.js';
import './vendor';
import './pages';

App.openHelpImage = (imagePath) => {
  window.open(imagePath, null, 'width=800, height=800');
};

App.notifySuccess = (message) => {
  $.notify({
    message,
  }, {
    type: 'success',
  });
};

App.notifyError = (message) => {
  $.notify({
    message,
  }, {
    type: 'danger',
  });
};
