$(document).on('click', '.js-activity-dropdown', function(e){
  if ($('.js-activity-badge').length > 0) {
    let url = $(this).data('url');
    Rails.ajax({
      type: 'PATCH',
      url: url,
      dataType: 'script'
    });
  }
});
