import { onConnect } from '@sonicgarden/onconnect';

import Select2 from 'select2';

Select2();

onConnect('.js-select2', (element) => {
  $(element).select2();
  return () => {
    $(element).select2('destroy');
  };
});

onConnect('.js-select2-for-tag', (element) => {
  $(element).select2({
    tags: true,
    multiple: true,
  });
  return () => {
    $(element).select2('destroy');
  };
});

onConnect('.js-select2-for-category', (element) => {
  $(element).select2({
    tags: true,
    multiple: false,
  });
  return () => {
    $(element).select2('destroy');
  };
});
