document.addEventListener('DOMContentLoaded', () => {

  document.querySelectorAll('.js-toggle-password').forEach((link) => {
    link.addEventListener('click', (e) => {
      e.preventDefault();

      const input = link.previousElementSibling;

      if (input.type === 'password') {
        input.type = 'text';
        link.classList.remove('fa-eye-slash');
        link.classList.add('fa-eye');
      } else {
        input.type = 'password';
        link.classList.add('fa-eye-slash');
        link.classList.remove('fa-eye');
      }
    });
  });
});
