$(function() {
  let changeBankType = function changeBankType() {
    let form = $('.js-bank-account-form');
    if ($('.js-bank-type').val() === 'bank') {
      form.find('.js-bank-account-inputs').removeClass('hide');
      form.find('.js-post-account-inputs').addClass('hide');
    } else {
      form.find('.js-post-account-inputs').removeClass('hide');
      form.find('.js-bank-account-inputs').addClass('hide');
    }
  };
  changeBankType();

  $('.js-bank-type').on('change', changeBankType);
});
