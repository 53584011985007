let key = function(textarea) {
  return 'physical-data-'.concat(textarea.dataset.autosave);
};

let save = function(textarea) {
  localStorage.setItem(key(textarea), textarea.value);
};

let load = function(textarea) {
  let value = localStorage.getItem(key(textarea));
  return value;
};

let debouncedSave = _.debounce(save, 200);

let onInput = function(e) {
  debouncedSave(e.currentTarget);
};

let clear = function() {
  $('.js-autosave').each(function(i, textarea) {
    localStorage.removeItem(key(textarea));
  });
};

$(function() {
  $('.js-autosave').each(function(i, textarea) {
    let value = load(textarea);
    if (value) {
      textarea.value = value;
    }
    $(textarea).on('input', onInput);
  });

  $('.js-autosave').closest('form').on('submit', clear);
});
