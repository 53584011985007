import Sortable from 'sortablejs';
import { onConnect } from '@sonicgarden/onconnect';

onConnect('.js-sortable', (element) => {
  const handleClass = element.dataset.sortableHandleClass;
  Sortable.create(
    element,
    {
      handle: handleClass,
      ghostClass: 'tw-bg-gray-100',
      chosenClass: 'tw-bg-yellow-100',
    },
  );
});
