document.addEventListener('DOMContentLoaded', () => {
  const input = document.querySelector('.js-inquiry-category');
  if (!input) {
    return;
  }
  const textarea = document.querySelector('.js-inquiry-text');

  const updateText = () => {
    const value = input.value;
    if (value === 'insurance' && textarea.value.length === 0) {
      textarea.value = `「予約ＩＤ」

「事故⽇時」

「事故のあった場所」

「被害者情報」

「加害者情報」

「事故概要」
`;
    }
    $('textarea').trigger('input');
  };

  updateText();

  input.addEventListener('change', () => {
    updateText();
  });
});
