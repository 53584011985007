import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['price', 'quantity', 'taxRate', 'totalPrice', 'sellPrice', 'sellQuantity', 'sellTotalPrice', 'profit'];

  static outlets = ['total-amount'];

  connect() {
    this.updateTotal();
  }

  updateTotal() {
    this.totalPriceTarget.textContent = this.totalPrice();

    if (this.hasSellPriceTarget) {
      this.sellTotalPriceTarget.textContent = this.sellTotalPrice();
      this.profitTarget.textContent = this.sellTotalPrice() - this.totalPrice();
    }

    if (this.hasTotalAmountOutlet) {
      this.totalAmountOutlet.update();
    }
  }

  totalPrice() {
    const price = this.priceTarget.value * this.quantityTarget.value;
    if (this.hasTaxRateTarget) {
      return Math.floor(price * (1 + this.taxRateTarget.value / 100));
    }
    return price;
  }

  sellTotalPrice() {
    return this.sellPriceTarget.value * this.sellQuantityTarget.value;
  }
}
