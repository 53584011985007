$(document).on('change', '.js-form-toggle-group-toggle', function (e) {
  const input = $(this).closest('.js-form-toggle-group').find('.js-form-toggle-group-child');
  input.prop('disabled', (index, value) => !value);
});

$(document).on('change', '.js-level-of-care', function (e) {
  $('.js-care_requirement_authorized_on').prop('disabled', $(this).val() === 'none');
});

$(document).on('change', '.js-report-image-input', (e) => {
  $('.js-image-cache-input').remove();
});
