import flatpickr from 'flatpickr';
import { Ja } from 'flatpickr/dist/l10n/ja.js';
import { onConnect } from '@sonicgarden/onconnect';

const addDate = function addDate(date) {
  const year = 1900 + date.getYear();
  const month = 1 + date.getMonth();
  const day = date.getDate();
  const dayOfWeekIndex = date.getDay();
  const dayOfWeek = ['日', '月', '火', '水', '木', '金', '土'][dayOfWeekIndex];

  const monthStr = month.toString().padStart(2, '0');
  const dayStr = day.toString().padStart(2, '0');

  const dateStrJa = `${year}/${monthStr}/${dayStr}（${dayOfWeek}）`;
  const dateStr = `${year}/${month}/${day}`;

  const $div = $(`<div><strong>${dateStrJa}</strong> <a href="#" class="fa-fw link-unstyled js-delete-date-of-use"><i class="fa fa-trash"></i></a><input type="hidden" value="${dateStr}" name="request[dates][]" /></div>`);
  $('.js-date-of-use-list').append($div);
};

onConnect('.js-flatpickr', (element) => {
  flatpickr(element, {
    dateFormat: 'Y-m-d',
    locale: 'ja',
    disableMobile: true,
  });
});

onConnect('.js-flatpickr-future', (element) => {
  flatpickr(element, {
    dateFormat: 'Y-m-d',
    minDate: new Date().fp_incr(1),
    locale: 'ja',
    disableMobile: true,
  });
});

onConnect('.js-request-date-of-use', (element) => {
  element.addEventListener('click', (e) => {
    e.preventDefault();
  });

  flatpickr(element, {
    dateFormat: 'Y-m-d',
    minDate: new Date(),
    locale: 'ja',
    disableMobile: true,
    onChange: (selectedDates, dateStr, instance) => {
      addDate(selectedDates[0]);
    },
  });
});

$(document).on('click', '.js-delete-date-of-use', function (e) {
  $(this).parent().remove();
  e.preventDefault();
});
