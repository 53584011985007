const Trix = require('trix');
require('@rails/actiontext');

document.addEventListener('trix-initialize', (event) => {
  const italic = event.target.toolbarElement
    .querySelector('.trix-button-group--text-tools .trix-button[data-trix-attribute=italic]');
  italic.parentNode.removeChild(italic);

  const element = event.target.toolbarElement
    .querySelector('.trix-button-group--block-tools .trix-button[data-trix-attribute=heading1]');
  element.parentNode.removeChild(element);

  let buttonHTML2 = `
  <button type="button" class="trix-button" data-trix-attribute="heading1">h1</button>
  <button type="button" class="trix-button" data-trix-attribute="heading2">h2</button>
  <button type="button" class="trix-button" data-trix-attribute="heading3">h3</button>
`;
  event.target.toolbarElement
    .querySelector('.trix-button-group--block-tools')
    .insertAdjacentHTML('afterbegin', buttonHTML2);
});

Trix.config.blockAttributes.heading2 = {
  tagName: 'h2'
};
Trix.config.blockAttributes.heading3 = {
  tagName: 'h3'
};

// 画像ファイルのうち特定のフォーマット以外はアップロードできないようにする
window.addEventListener('trix-file-accept', function(event) {
  const acceptedTypes = ['image/png', 'image/gif', 'image/jpg', 'image/jpeg'];
  if (event.file.type.startsWith('image') && !acceptedTypes.includes(event.file.type)) {
    event.preventDefault();
    const title = `${event.file.name}は対応していないファイル形式です。`;
    window.alert(title);
  }
});
