$(function() {
  const changeMediaType = () => {
    const form = $('.js-accommodation-review-form');
    if ($('.js-media-type:checked').val() === 'image') {
      form.find('.js-image-inputs').removeClass('hide');
      form.find('.js-video-inputs').addClass('hide');
    } else {
      form.find('.js-video-inputs').removeClass('hide');
      form.find('.js-image-inputs').addClass('hide');
    }
  };
  changeMediaType();

  $('.js-media-type').on('change', changeMediaType);

  document.querySelectorAll('.js-adjust-scroll-center').forEach((elm) => {
    const width = elm.clientWidth;
    const scrollWidth = elm.scrollWidth;
    const leftOffset = scrollWidth / 2.0 - width / 2.0;
    elm.scrollLeft = leftOffset;
  });
});
