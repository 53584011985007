document.addEventListener('DOMContentLoaded', () => {
  if ($('.js-notice').length > 0) {
    const messages = $('.js-notice').data('messages');
    Object.keys(messages).forEach((key) => {
      const { message, type } = messages[key];
      $.notify({
        message: message
      }, {
        type: type,
        z_index: 10000
      });
    });
  }
});
