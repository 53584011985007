import { IntersectionController } from 'stimulus-use';

export default class extends IntersectionController {
  static values = {
    url: String
  }

  appear(entry) {
    fetch(this.urlValue, {
      method: 'PUT',
      headers: {
        'X-CSRF-Token': Rails.csrfToken(),
      },
    });
  }

  disappear(entry) {
  }
}
