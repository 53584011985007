let calcDuration = function(start, finish) {
  let duration;
  let startTime = moment('20000101 ' + start, 'YYYYMMDD HH:mm');
  let finishTime = moment('20000101 ' + finish, 'YYYYMMDD HH:mm');

  let finishHour = Number(finish.substr(0, 2));
  if (finishHour >= 24) {
    finishHour -= 24;
    let finishMin = finish.substr(3, 2);
    let newFinishText = finishHour + ':' + finishMin;
    finishTime = moment('20000102 ' + newFinishText, 'YYYYMMDD HH:mm');
  }
  return duration = moment.duration(finishTime.diff(startTime));
};

let setTotalPrice = function() {
  let sum = 0;
  $('.js-plan-billing-row:visible').each(function(index, element){
    let price = $(element).find('.js-plan-price-input').val();
    let quantity = $(element).find('.js-plan-quantity-input').val();
    sum += price * quantity;
  });
  $('.js-planning-total-price').text(sum);
};

$(document).on('change', '.js-plan-price-input, .js-plan-quantity-input', function() {
  setTotalPrice();
});

$(document).on('click', '.js-plan-billing-remove', function() {
  // cocoon:after-removeがうまく動いてくれないので回避対応
  setTimeout(() => {
    setTotalPrice();
  }, 300);
});

$(function() {

  // selectをreadonly相当にしたい
  // https://github.com/vinicius-stutz/jquery-selection/blob/master/jquery.selection.js
  $('.js-readonly-select')
    .attr('unselectable', 'on')
    .css('user-select', 'none')
    .css('-moz-user-select', 'none')
    .css('-khtml-user-select', 'none')
    .css('-webkit-user-select', 'none')
    .on('selectstart', false)
    .on('contextmenu', false)
    .on('keydown', false)
    .on('mousedown', false);

  $('.js-plan-starts-at-select, .js-plan-finishes-at-select').change(function() {
    let start = $('.js-plan-starts-at-select').get(0).value + ':' + $('.js-plan-starts-at-select').get(1).value;
    let finish = $('.js-plan-finishes-at-select').get(0).value + ':' + $('.js-plan-finishes-at-select').get(1).value;

    let diff = calcDuration(start, finish);
    let quantity = Math.ceil(diff.asMinutes() / 30) * 0.5;
    $('.js-main-fee-quantity-input').val(quantity);
    setTotalPrice();
  });

  $('.js-plan-service-type-radio').change(function() {
    if ($(this).val() === 'livelihood') {
      $('.js-main-fee-price-input').val(2000);
    } else {
      $('.js-main-fee-price-input').val(3000);
    }
    setTotalPrice();
  });
});
